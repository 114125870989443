
import { defineComponent, onMounted } from "vue";
import DarkModeSwitcher from "@/components/dark-mode-switcher/Main.vue";
import router from "@/router";

export default defineComponent({
  methods: {
    router() {
      return router
    }
  },
  components: {
    DarkModeSwitcher,
  },
  setup() {
    onMounted(() => {
      cash("body")
        .removeClass("app")
        .removeClass("login")
        .addClass("error-page");
    });

  },
});
